<template>
  <div>
    <block v-if="loading"></block>
    <v-form v-model="valid">
      <v-row class="ml-2 mr-2">
        <v-col class="tabsNH" cols="12">
          <v-col
            cols="12"
            v-if="customerToShow"
            style="display: flex; justify-content: space-between; min-height: 50px"
          >
            <div>
              <v-btn
                small
                v-if="$store.getters['rolesUser/getCustomRole'](19)"
                color="error"
                @click="confirmDeleteCustomer()"
              >
                <template v-if="isBusiness">
                  <v-icon style="margin-left: 0.1rem"> mdi-delete </v-icon>
                </template>
                <template v-else>
                  <v-icon style="margin-left: 0.1rem"> mdi-delete </v-icon>
                </template>
              </v-btn>
            </div>

            <div>
              <v-btn
                v-if="
                  businesstype == 'bloodtestlaboratory' &&
                  (rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||
                    rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 ||
                    rolesStatus.findIndex((role) => role.ID_Role == 33) > -1)
                "
                small
                class="mx-2 btn-add"
                @click="
                  $store.dispatch('expanedWindow', { uuid: 0 }).then(() => {
                    $store.dispatch('expanedWindow', { uuid: 1 });
                    $store.commit('posData/setCustomer', customerToShow);
                    $store.commit('posData/changeView', 'tab-1');
                    $store.commit('posData/setReloaders', 'reloadCreate');
                  })
                "
              >
                Agregar examenes
              </v-btn>
            </div>

            <div>
              <v-btn
                small
                v-if="$store.getters['rolesUser/getCustomRole'](18)"
                class="mx-2 btnsave"
                @click.prevent="updateCustomer"
              >
                <template v-if="isBusiness">
                  <v-icon style="margin-left: 0.1rem"> mdi-content-save </v-icon>
                </template>
                <template v-else>
                  <v-icon style="margin-left: 0.1rem"> mdi-content-save </v-icon>
                </template>
              </v-btn>
            </div>
          </v-col>

          <v-col
            cols="12"
            v-else
            style="display: flex; justify-content: flex-end; min-height: 50px"
          >
            <!--v-if="
                !isBusiness &&
                (businesstype == 'bloodtestlaboratory' ||
                  businesstype == 'medicalclinic') &&
                ($store.getters['rolesUser/getCustomRole'](173))
              "-->
            <v-btn
              small
              class="btnsave"
              v-if="
                !isBusiness &&
                (businesstype == 'bloodtestlaboratory' ||
                  businesstype == 'medicalclinic') &&
                ($store.getters['rolesUser/getCustomRole'](17))"
              @click.prevent="addCustomerHandler(true)"
              style="margin-right: "
            >
              <v-icon class="mr-2">mdi-account-plus-outline</v-icon>
              Agregar Cliente como Paciente
            </v-btn>
            <v-btn
              small
              v-if="
                (businesstype == 'bloodtestlaboratory' ||
                  businesstype == 'medicalclinic') &&
                ($store.getters['rolesUser/getCustomRole'](17))
              "
              class="mx-2 btnsave"
              @click.prevent="addCustomerHandler"
            >
              <template v-if="isBusiness"><v-icon class="mr-2">mdi-bank-plus</v-icon> Agregar negocio </template>
              <template v-else> <v-icon class="mr-2">mdi-account-plus</v-icon> Agregar Cliente </template>
            </v-btn>
            <v-btn
              v-if="
                (businesstype == 'veterinary' ||
                businesstype == 'accounting_and_bill') &&
                  $store.getters['rolesUser/getCustomRole'](17)
              "
              small
              class="mx-2 btnsave"
              @click.prevent="addCustomerHandler"
            >
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </v-col>
        </v-col>

        <v-col cols="12" :class="customerToShow ? 'mt-2 mb-7' : ''">
          <v-card class="mt-3 px-2 py-1">
            <v-row class="mt-2" align="stretch">
              <v-col cols="12" :md="customerToShow ? 3 : 6" class="d-flex flex-column align-start">
                <div class="text-h6 black--text">Información General</div>
                <div>Nota: los apartados con (*) son datos requeridos</div>
              </v-col>
              <v-col cols="12" md="3" lg="3" xl="2" v-if="customerToShow">
                <div class="text-h6">
                  <b>ID cliente:</b> {{ form.id_customer }}
                </div>
              </v-col>
              <v-col cols="12" md="3" class="d-flex align-center">
                <v-checkbox
                  hide-details
                  v-model="form.status"
                  :label="`Estado: ${form.status ? 'activo' : 'inactivo'}`"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" class="d-flex align-center">
                <v-checkbox
                  hide-details
                  v-model="form.isGlobalBranch"
                  label="Mostrar en Todas las Sucursales"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row cols="12" class="mb-2">
              <v-col cols="12" md="3">
                <v-checkbox
                hide-details
                :readonly="isPublicEntity  || isOng"
                v-model="isBusiness" label="Es Tributario/Negocio"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox 
                hide-details
                :readonly="isBusiness || isPublicEntity "
                v-model="isOng" label="ONG"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox 
                hide-details
                :readonly="isOng || isBusiness"
                v-model="isPublicEntity" label="Institución Pública"></v-checkbox>
              </v-col>
              <v-col cols="6" md="3">
                <v-btn
                  v-if="modeEnterprise"
                  @click.prevent="showLegaInfo = true"
                  color="comment"
                  dark
                  class="text-subtitle-1 rounded-lg font-weight-bold"
                  block
                >
                  Información Tributaria
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" :class="customerToShow ? 'my-2' : 'my-6'">
          <template v-if="!modeEnterprise">
            <v-row cols="12">
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  v-model="form.firstname"
                  label="Nombre: *"
                  required
                  :rules="required"
                  @input="uppercaseInput('firstname')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  v-model="form.lastname"
                  label="Apellido: *"
                  required
                  :rules="required"
                  @input="uppercaseInput('lastname')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                :md="businesstype != 'veterinary' && businesstype != 'bill' ? 4 : 6"
              >
                <v-text-field
                  outlined
                  dense
                  v-if="form.legalname"
                  required
                  :rules="required"
                  v-model="form.legalname"
                  label="Nombre en factura: *"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-else
                  :value="FullName"
                  required
                  :rules="required"
                  label="Nombre en factura: *"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
                v-if="businesstype != 'veterinary' && businesstype != 'bill'"
              >
                <v-select
                  outlined
                  dense
                  attach
                  :items="gender"
                  label="Sexo: "
                  v-model="form.gender"
                ></v-select>
              </v-col>
            </v-row>

            <v-row cols="12">
              <v-col cols="12" md="3">
                <date-picker
                  label="Fecha de nacimiento: "
                  @dateSelected="(param) => (date = param)"
                  v-model="date"
                  :value="date"
                ></date-picker>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field
                  outlined
                  dense
                  v-model="form.age"
                  label="Edad: "
                  type="number"
                  @keyup="calculateDOBwithAge()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                  outlined
                  dense
                  attach
                  :items="prefijo"
                  label="Prefijo: "
                  v-model="form.prefijo"
                  item-text="ParameterValue"
                  item-value="ParameterValue"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  v-model="form.phonenumber"
                  @input="maskNum"
                  label="Número de télefono:"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  v-model="form.mobilenumber"
                  @input="maskNum"
                  required
                  :rules="required"
                  label="Celular: * "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <!--v-col cols="12" :class="`fast-ease-in-transitioned-element ${!reactive.isNit ? 'item-into-three-element-row' : 'item-into-two-element-row'}`"-->
                <v-text-field
                  outlined
                  dense
                  v-model="form.email"
                  class="fast-ease-in-transitioned-element"
                  required
                  :rules="required"
                  label="Correo eléctronico *: "
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  outlined
                  dense
                  :value="form.address"
                  @change="addressDetails($event, 'address')"
                  required
                  :rules="required"
                  label="Dirección:* "
                  @input="uppercaseInput('address')"
                />
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row cols="12">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  ref="businessname"
                  clearable
                  v-model="businessForm.businessname"
                  label="Nombre del negocio: *"
                  placeholder="Escribe el nombre del negocio"
                  required
                  :rules="required"
                  @input="uppercaseBusiness('businessname')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  v-model="businessForm.businessemail"
                  label="Correo del negocio:* "
                  required
                  :rules="required"
                  placeholder="Escribe el correo del negocio"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row cols="12">
              <v-col cols="12" md="2">
                <v-text-field
                  outlined
                  dense
                  v-model="businessForm.businessphone"
                  @input="maskNum"
                  label="Télefono del negocio: "
                  placeholder="Escribe el télefono del negocio"
                />
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  outlined
                  dense
                  v-model="businessForm.businessmobile"
                  @input="maskNum"
                  label="Celular del negocio:* "
                  required
                  :rules="required"
                  placeholder="Escribe el celular del negocio"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  outlined
                  dense
                  v-model="businessForm.legaladdress"
                  required
                  :rules="required"
                  label="Dirección Legal* "
                  @input="uppercaseBusiness('legaladdress')"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <v-row cols="12">
            <v-col cols="12" md="3">
              <v-autocomplete
                outlined
                dense
                :items="countryList"
                attach
                item-text="name"
                item-value="name"
                v-model="form.country"
                required
                :rules="required"
                @change="getStates"
                label="País*"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                outlined
                dense
                required
                :rules="required"
                v-if="form.country == 'El Salvador'"
                :items="departments"
                v-model="form.department"
                label="Estado/Departamento*"
                item-text="Valores"
                item-value="Codigo"
                attach
              >
              </v-autocomplete>
              <v-autocomplete
                outlined
                dense
                required
                :rules="required"
                v-else
                v-model="form.department"
                label="Estado/Departamento*"
                :items="stateList"
                item-text="name"
                @change="getCities"
                attach
              >
                <template v-slot:no-data>
                  <div class="px-4">{{ noDataLabelStates }}</div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                outlined
                dense
                required
                :rules="required"
                :items="municipalities"
                v-if="form.country == 'El Salvador'"
                :readonly="!form.department"
                v-model="form.municipality"
                label="Ciudad*"
                item-text="Valores"
                item-value="Codigo"
                attach
              />
              <div v-else>
                <v-autocomplete
                  outlined
                  dense
                  required
                  :rules="required"
                  v-if="cityList.length > 0 || noDataLabelCities === 'Cargando...'"
                  v-model="form.municipality"
                  label="Ciudad*"
                  item-text="name"
                  :readonly="!form.department"
                  :items="cityList"
                  attach
                >
                  <template v-slot:no-data>
                    <div class="px-4">{{ noDataLabelCities }}</div>
                  </template>
                </v-autocomplete>
                <v-text-field
                  outlined
                  dense
                  required
                  :rules="required"
                  v-else
                  v-model="form.municipality"
                  label="Ciudad*"
                  placeholder="Digite la ciudad del cliente"
                />
              </div>
            </v-col>
            <v-col cols="12" md="3" v-if="modeEnterprise">
              <v-text-field
                outlined
                dense
                v-model="businessForm.businesswebsite"
                label="Sitio web del negocio: "
                placeholder="Escribe el sitio web del negocio"
              />
            </v-col>

            <v-col cols="12" md="3" v-if="!modeEnterprise">
              <!--v-col cols="12" :class="`fast-ease-in-transitioned-element ${!reactive.isNit ? 'item-into-three-element-row' : 'item-into-two-element-row'}`"-->
              <v-autocomplete
                outlined
                dense
                label="Tipo de documento"
                class="fast-ease-in-transitioned-element"
                v-model="form.documenttype"
                :items="documentTypes"
                item-text="DocumentType"
                item-value="ID_DocumentType"
                v-on:change="onChangeDocumentTypeSelect"
                attach
              />
            </v-col>

            <v-fade-transition>
              <v-col cols="12" md="3" v-show="reactive.showDocument" v-if="!modeEnterprise">
                <v-text-field
                  outlined
                  dense
                  v-model="form.dui"
                  @input="
                    () => {
                      varifycontent('form', 'dui');
                    }
                  "
                  :label="reactive.documentLabel"
                />
              </v-col>
            </v-fade-transition>
            <v-fade-transition>
              <v-col cols="12" md="3" v-show="reactive.isNit" v-if="!modeEnterprise">
                <v-text-field
                  outlined
                  dense
                  v-model="form.legalnit"
                  label="Número de NIT:"
                  @keyup="maskDocumentNit"
                ></v-text-field>
              </v-col>
            </v-fade-transition>
            <v-col cols="12" md="3" v-if="!modeEnterprise">
                <v-text-field outlined dense v-model="form.oldid" label="Código alterno"
                  placeholder="Escriba el Código alterno"></v-text-field>
              </v-col> 
              <v-col cols="12" md="3" v-else>
                <v-text-field outlined dense v-model="businessForm.oldid" label="Código alterno"
                  placeholder="Escriba el Código alterno"></v-text-field>
              </v-col>
          </v-row>

          <!-- 
            <v-col cols="12" md="3">
              <v-text-field outlined dense :value="form.state" @change="addressDetails($event, 'state')"
                label="Departamento: " @input="uppercaseInput('state')"></v-text-field>
            </v-col>
        <v-col cols="12" md="3">
              <v-text-field outlined dense :value="form.country" @change="addressDetails($event, 'country')"
                label="País: " @input="uppercaseInput('country')"></v-text-field>
            </v-col> -->

          <v-row> </v-row>
          <v-row v-if="!modeEnterprise">
            <v-col cols="12" class="my-3" v-show="false">
              <v-row>
                <v-col cols="3">
                  <v-checkbox v-model="form.sendemail" label="Envíar correo"></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox v-model="form.sendsms" label="Envíar SMS"></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="form.sendwhatsapp"
                    label="Envíar Whatsapp"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="form.sendpushup"
                    label="Envíar Pushup"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="12" md="12" style="margin-top: 0.8rem"> </v-col>
                <br /><br /><br />
                <!--v-dialog
           v-model="showLegaInfo"
           width="60%"
           >
           <v-card class="pa-4">
             <v-card-title>
               <v-col cols="10" class="text-h5">
                 Información Tributaria
               </v-col >
               <v-col cols="2"  class="text-right">
                 <v-btn small class="btndelete" @click.prevent="showLegaInfo = false" >
                   <v-icon>mdi-close</v-icon>
                 </v-btn>
               </v-col>
             </v-card-title>
             <v-card-text>
               <v-row class="mt-2">
               
                 
                </v-row>
                <v-row-->

                <!-- Para facturas ya no hay información legal-->
                <!--/v-row>
             </v-card-text>
             <v-card-actions>
               <v-col class="text-right">
                 <v-btn @click="showLegaInfo = !showLegaInfo" class="btndisable">
                   Continuar
                 </v-btn>
               </v-col>
             </v-card-actions>
           </v-card>
         </v-dialog-->
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" style="margin-top: 0.8rem">
                  <h3>Información de la persona de contacto principal del negocio</h3>
                </v-col>
                <br /><br />
                <v-col cols="12" md="2">
                  <v-select
                    outlined
                    dense
                    attach
                    :items="prefijo"
                    label="Prefijo: "
                    v-model="form.prefijo"
                    item-text="ParameterValue"
                    item-value="ParameterValue"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    outlined
                    dense
                    v-model="businessForm.businesscontactname"
                    label="Nombre contacto: *"
                    placeholder="Escribe el contacto del negocio"
                    required
                    :rules="required"
                    @input="uppercaseBusiness('businesscontactname')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                    outlined
                    dense
                    v-model="businessForm.businesscontactemail"
                    label="Correo eléctronico contacto*"
                    required
                    :rules="required"
                    placeholder="Correo eléctronico del contacto"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row v-show="false" class="my-3">
                <v-col cols="3">
                  <v-checkbox
                    v-model="businessForm.sendemail_businessemail"
                    label="Enviar correo al negocio"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="businessForm.sendsms_businessmobile"
                    label="Envíar SMS al negocio"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="businessForm.sendwhatsapp_businessmobile"
                    label="Envíar Whatsapp al negocio"
                  ></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="businessForm.sendpushup"
                    label="Envíar Pushup al negocio"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="my-3">
                <v-col cols="12" md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="businessForm.businesscontactphone"
                    label="Télefono contacto:* "
                    @input="maskNum"
                    placeholder="Télefono del contacto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    outlined
                    dense
                    v-model="businessForm.businesscontactmobile"
                    label="Celular contacto:* "
                    @input="maskNum"
                    placeholder="Celular del contacto"
                    required
                    :rules="required"
                  ></v-text-field>
                </v-col>

                <!--v-col cols="12" :class="`fast-ease-in-transitioned-element ${!reactive.isNit ? 'item-into-three-element-row' : 'item-into-two-element-row'}`"-->
                <v-col cols="12" md="3">
                  <v-autocomplete
                    outlined
                    dense
                    label="Tipo de documento"
                    class="fast-ease-in-transitioned-element"
                    v-model="businessForm.documenttype"
                    :items="documentTypes"
                    item-text="DocumentType"
                    item-value="ID_DocumentType"
                    v-on:change="onChangeDocumentTypeSelect"
                    attach
                  />
                </v-col>
                <v-fade-transition>
                  <v-col cols="12" md="3" v-show="reactive.showDocument">
                    <v-text-field
                      outlined
                      dense
                      v-model="businessForm.dui"
                      @input="
                        () => {
                          varifycontent('businessForm', 'dui');
                        }
                      "
                      :label="reactive.documentLabel"
                    />
                  </v-col>
                </v-fade-transition>
                <v-fade-transition>
                  <v-col cols="12" md="3" v-show="reactive.isNit">
                    <v-text-field
                      outlined
                      dense
                      v-model="businessForm.dui"
                      @keyup="keyUpMaskNit"
                      label="Número de NIT:"
                    />
                  </v-col>
                </v-fade-transition>
              </v-row>
            </v-col>
            <!--   <v-col cols="12" sm="12" md="12" style="margin-top: .8rem;">
                  <h3
                  @click.prevent="showLegaInfo = !showLegaInfo"
                  class="tabs"
                  >Informacion Legal
                  <v-icon v-if="showLegaInfo" color="white" left>
                          mdi-minus
                        </v-icon>
                        <v-icon v-else color="white" left> mdi-plus </v-icon>
                </h3>
                </v-col> -->
            <!-- si es negocio -->
            <v-dialog v-model="showLegaInfo" :width="dialogWidth" scrollable>
              <v-card class="pa-4" style="height: inherit;">
                <v-card-title>
                  <v-col cols="10" class="text-h5"> Información Tributaria </v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn small class="btndelete" @click.prevent="showLegaInfo = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-card-title>
                <v-card-text>
                  <v-row class="mt-2">
                    <v-col cols="12" md="6" v-show="showLegaInfo">
                      <v-text-field
                        outlined
                        dense
                        v-model="businessForm.legalname"
                        required
                        :rules="required"
                        label="Nombre Legal*"
                        @input="uppercaseBusiness('legalname')"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="form.country != 'El Salvador'" cols="12" md="6">
                      <v-autocomplete
                        outlined
                        dense
                        label="País/Estado Receptor"
                        :items="countriesMh"
                        required
                        :rules="required"
                        attach
                        v-model="form.CountryMH"
                        item-text="Valores"
                        item-value="Valores"
                      ></v-autocomplete>
                    </v-col>

                  </v-row>
                  <v-row>
                    <v-col v-if="form.country === 'El Salvador'" cols="12" md="3" v-show="showLegaInfo">
                      <v-text-field
                        outlined
                        dense
                        v-model="businessForm.legalnrc"
                        :required = "isBusiness && form.country != 'El Salvador'"
                        :rules="isBusiness && form.country === 'El Salvador' ? required : []"
                        :label="isBusiness && form.country === 'El Salvador' ? 'NRC Legal:*' : 'NRC Legal:'"
                        @input="maskNRC"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="form.country === 'El Salvador'" cols="12" md="3" v-show="showLegaInfo">
                      <v-text-field
                        outlined
                        dense
                        v-model="businessForm.legalnit"
                        :required="isBusiness && form.country != 'El Salvador'"
                        :rules="isBusiness && form.country === 'El Salvador' ? required : []"
                        :label="isBusiness && form.country === 'El Salvador' ? 'Número de NIT:*' : 'Número de NIT:'"
                        @keyup="maskNit"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete v-if="form.country === 'El Salvador'"
                        outlined
                        dense
                        label="Actividad economica:*"
                        :items="activities"
                        @change="changueValueactivity"
                        required
                        :rules="required"
                        attach
                        v-model="form.codactividad"
                        item-text="Valores"
                        item-value="Codigo"
                      ></v-autocomplete>
                      <v-text-field v-else
                        outlined
                        dense
                        label="Actividad economica:*"
                        v-model="businessForm.typedDescActividad"
                        required
                        :rules="required"
                      >

                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <!-- <v-col cols="12"  md="4" v-show="showLegaInfo">
                    <v-text-field outlined dense v-model="businessForm.legalsector"  required :rules="required"  label="Sector Legal* " @input="uppercaseBusiness('legalsector')"></v-text-field>
                  </v-col> -->
                    <v-col cols="12" md="6" v-show="showLegaInfo">
                      <!--                                                <v-text-field-->
                      <!--                                                        v-model="form.providerlegalentity"-->
                      <!--                                                        label="Provider legal Entity"-->
                      <!--                                                ></v-text-field>-->
                      <v-select
                        outlined
                        dense
                        attach
                        :items="businessEntity"
                        :label="isBusiness ? 'Entidad Legal*' : 'Entidad Legal'"
                        :required = "isBusiness"
                        :rules="isBusiness ? required : []"
                        v-model="businessForm.legalentity"
                      ></v-select>
                    </v-col>
                    <v-col v-if="form.country === 'El Salvador'" cols="12" md="6" v-show="showLegaInfo">
                      <!--                                                <v-text-field-->
                      <!--                                                        v-model="form.providerlegalentity"-->
                      <!--                                                        label="Provider legal Entity"-->
                      <!--                                                ></v-text-field>-->
                      <v-select
                        outlined
                        dense
                        attach
                        :items="businessSize"
                        :label="isBusiness ? 'Tamaño Legal*' : 'Tamaño Legal'"
                        :required = "isBusiness"
                        :rules="isBusiness ? required : []"
                        v-model="businessForm.legaltaxpayersize"
                      ></v-select>
                    </v-col>
                    <!-- <v-col cols="12" sm="6" md="4">
                        <v-text-field-->
                    <!--                                                        v-model="form.providerlegaltaxpayersize"-->
                    <!--                                                        label="Provider Legal Tax Payer Size"-->
                    <!--                                                ></v-text-field>
                          </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" v-show="isPublicEntity">
                      <v-checkbox
                        v-model="businessForm.removerestrictionpublicentity"
                        label="Quitar restricción de $100 para retención del 1%"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6" v-show="showLegaInfo">
                      <v-checkbox
                        v-model="businessForm.legalreterenta"
                        label="Retiene 10% renta"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6" v-show="showLegaInfo">
                      <v-checkbox
                        v-model="businessForm.legalagentretain"
                        label="Es agente de retención 1%"
                      ></v-checkbox>
                    </v-col>
                    <!--v-col cols="12" md="6" v-show="showLegaInfo">
                      <v-checkbox
                        v-model="businessForm.legalisnontaxable"
                        label="Cliente Libre de Impuestos"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6" v-show="showLegaInfo">
                      <v-checkbox
                        v-model="businessForm.legalisexempt"
                        label="Cliente Exento"
                      ></v-checkbox>
                    </v-col-->
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-col class="text-right">
                    <v-btn @click="showLegaInfo = !showLegaInfo" class="btndisable">
                      Continuar
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>

      
            <v-row>
            <v-col cols="12" md="3" :class="customerToShow ? 'mb-2' : 'mb-0'" >
              <v-select
                dense
                outlined
                v-model="form.typeinvoice"
                :items="ItemsTypeInvoice"
                item-value="id"
                item-text="value"
                label="Tipo de factura por defecto"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" :class="customerToShow ? 'mb-2' : 'mb-0'" >
              <v-select
                dense
                outlined
                :items="['Cancelada', 'Crédito']"
                v-model="form.statusinvoice"
                label="Estado de factura por defecto"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" :class="customerToShow ? 'mb-2' : 'mb-0'" >
              <v-select
                dense
                outlined
                v-model="form.paymenttype"
                :items="paymentTypes"
                item-value="Valores"
                item-text="Valores"
                label="Método de pago por defecto"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" :class="customerToShow ? 'mb-2' : 'mb-0'" >
              <CustomAutoComplete
              label="Vendedor por defecto"
              v-model="form.idseller"
              :search="{ ...search }"
              :items="sellersList"
              :item-text="'FullName'"
              :filter-endpoint="$API.sellers.getAllSellersFiltered"
              :item-value="'ID_Seller'"
              @select="(e) => {form.idseller = e;}"
              @updateItems="updateSellersList"
              />
            </v-col>
            <v-row class="mb-5 ml-2">
              <v-col v-if="modeEnterprise" cols="12" md="3">
                <v-checkbox
                v-model="businessForm.sendemail_businessemail"
                label="Enviar correo al negocio"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" :class="customerToShow ? 'mb-2' : 'mb-0'">
                <v-checkbox
                hide-details
                v-model="form.isseveralcustomer"
                label="Es Clientes Varios"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" :class="customerToShow ? 'mb-2' : 'mb-0'">
                <v-checkbox
                hide-details
                v-model="form.legalisexempt"
                label="Cliente Exento"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" :class="customerToShow ? 'mb-2' : 'mb-0'" >
                <v-checkbox
                  hide-details
                  v-model="form.legalisnontaxable"
                  label="Cliente Libre de Impuestos"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-row>

        </v-col>

        <!--  <v-col cols="12" class="my-3">
            <v-row>
              

                <v-col cols="4" v-if="roleuser == 'ROLE_ROOT' || roleuser == 'ROLE_ADMIN'">
                <v-select outlined dense  attach :items="['Activo', 'Inactivo']" label="Estado: " v-model="form.status"></v-select>
              </v-col>
            </v-row>
          </v-col> -->

        <!--  <template v-else>
          <v-col cols="12" class="my-3">

            <v-row>
              <v-col cols="6" md="6">
                <h3>Información General</h3>
                <label>Nota: los apartados con (*) son datos requeridos</label>
              </v-col>
              <v-col cols="3">
                <v-checkbox v-model="businessForm.status"
                  :label="`Estado: ${businessForm.status ? 'activo' : 'inactivo'}`"></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-checkbox v-model="isBusiness" label="Este es un negocio"></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense v-model="businessForm.businessname" label=" negoNombre delcio: *"
                  placeholder="Escribe el nombre del negocio" required :rules="required" @input="uppercaseBusiness('businessname')"></v-text-field>
              </v-col>
             
              <v-col cols="3">
                <v-text-field outlined dense v-model="businessForm.businessphone" label="Télefono del negocio: "
                  placeholder="Escribe el télefono del negocio" required></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field outlined dense v-model="businessForm.businesswebsite" label="Sitio web del negocio: "
                  placeholder="Escribe el sitio web del negocio" required ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field outlined dense v-model="businessForm.businessmobile" label="Celular del negocio: "
                    placeholder="Escribe el celular del negocio" required></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field outlined dense v-model="businessForm.businessemail" label="Correo del negocio: "
                    placeholder="Escribe el correo del negocio" required></v-text-field>
                </v-col>

            </v-row>
          </template>
          </v-col> -->

        <!--   <v-col cols="12" class="my-3">
            <v-row>
             
            </v-row>
          </v-col> -->

        <v-col cols="12" md="6" class="mt-3">
          <h3
            @click.prevent="showConfigCredit = true"
            class="tabs"
            style="text-align: center"
          >
            Configuración de Crédito
          </h3>
        </v-col>
        <v-col cols="12" class="mt-3" md="6">
          <h3
            :class="form.notes ? 'tabsContent' : 'tabs'"
            @click.prevent="showNotes = !showNotes"
            style="text-align: center"
          >
            Comentarios
          </h3>
          <!--   <quill-editor v-show="showNotes" :content="form.notes"  :options="quillOptions" @change="onEditorChange($event)"></quill-editor> -->
        </v-col>
        <v-col cols="12" md="6" class="mt-3" v-if="EnableZoneConfiguration">
          <h3
            @click.prevent="showConfigZone = true"
            class="tabs"
            style="text-align: center"
          >
            Configuración de Zona
          </h3>
        </v-col>
      </v-row>
    </v-form>

    <br /><br />

    <v-dialog width="60%" v-model="showNotes">
      <v-card class="pa-4">
        <div
          style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          "
        >
          <v-btn small class="btndelete" @click.prevent="showNotes = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <quill-editor
          :content="form.notes"
          :options="quillOptions"
          @change="onEditorChange($event)"
        ></quill-editor>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfigCredit" :width="$vuetify.breakpoint.smAndDown ?'90%':'60%'">
      <v-card>
        <v-card-title> Configuración de crédito </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" md="4" v-if="$store.getters['rolesUser/getCustomRole'](227)">
              <v-text-field
                v-model="form.limitcredit"
                label="Límite de crédito permitido al cliente: "
                outlined
                dense
                type="number"
                prepend-icon="$"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" v-if="$store.getters['rolesUser/getCustomRole'](228)">
              <v-text-field
                v-model="form.creditperiod"
                label="Plazo de créditos: "
                outlined
                dense
                type="number"
                prepend-icon="mdi-calendar-today"
                append-icon="días"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" v-if="$store.getters['rolesUser/getCustomRole'](229)">
              <v-select
                  outlined
                  attach
                  dense
                  :items="['BLOQUEADO', 'DISPONIBLE', 'INACTIVO', 'SUSPENDIDO']"
                  label="Estado de créditos: "
                  v-model="form.creditstatus"
                ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn @click="showConfigCredit = !showConfigCredit" class="btndisable">
              Continuar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfigZone" width="40%" scrollable>
      <v-card>
        <v-card-title> Configuración de zona </v-card-title>
        <v-card-text>
          <v-row class="mt-3" style="margin-bottom: 75px">
            <v-col cols="12" md="12">
              <v-select
                :items="ZoneConfiguration"
                v-model="form.zoneclient"
                label="Zona del cliente: "
                outlined
                attach
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-right">
            <v-btn @click="showConfigZone = !showConfigZone" class="btndisable">
              Continuar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>
  </div>
</template>

<script>
import DatePicker from "../../../components/DatePicker";
import Vue from "vue";
import Block from "../../../components/Block";
import Alerts from "../../../components/Alerts";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapState, mapGetters } from "vuex";
import {
  dui as duiMask,
  nit as nitMask,
  nrc as nrcMask,
  applyDUIMaskOutOfInput,
  applyNitMaskHandlingHomologationOutOfInput,
  numberSV,
  nitMaskHandlingHomologation,
} from "../../../helpers/masks";
import { validateNITHomologation } from "../../../helpers/object";
import CustomAutoComplete from "../../../components/CustomAutoComplete.vue";
//import { countryList } from "../../../helpers/countrylist";
 
export default {
  name: "ManageCustomers",
  components: { Block, DatePicker, Alerts, CustomAutoComplete },
  props: ["customerToShow", "win"],
  data() {
    return {
      documentTypes: [],
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      showBgcolorNotes: "tabs",
      showNotes: false,
      roleuser: null,
      valid: false,
      quillOptions: quillConfig,

      date: null,
      form: {
        firstname: "",
        lastname: "",
        legalname: "",
        country: "",
        department: "",
        municipality: "",
        isGlobalBranch: true
      },
      //  countryList,
      businessForm: {},
      entity: ["PERSONA NATURAL", "PERSONA JURÍDICA"],
      businessEntity: [
        "PERSONA NATURAL",
        "PERSONA JURÍDICA",
        "SOCIEDAD ANÓNIMA",
        "CORPORACIÓN",
      ],
      size: ["PEQUEÑO"],
      businessSize: ["PEQUEÑO", "MEDIANO", "GRANDE"],
      prefijo: ["", "Sr.", "Sra.", "Srita.", "Dr.", "Dra.", "Lic.", "Licda.", "Ing."],
      gender: ["Masculino", "Femenino"],
      required: [(v) => !!v || "El campo es requerido"],
      emailRules: [
        (v) => !!v || "El correo es requerido",
        (v) => /.+@.+/.test(v) || "El correo debe de ser valido",
      ],
      loading: false,
      isPatient: false,
      isBusiness: false,
      isOng: false,
      isPublicEntity: false,
      business: null,
      branch: null,
      user: null,
      businesstype: null,
      showLegaInfo: false,
      dataresponse: null,
      reactive: {
        isNit: false,
        showDocument: true,
        documentLabel: "Seleccione un tipo de documento",
      },
      stateList: [],
      cityList: [],
      noDataLabelStates: "Seleccione un país",
      noDataLabelCities: "No hay ciudades disponibles",
      showConfigCredit: false,
      showConfigZone: false,
      ZoneConfiguration: [],
      EnableZoneConfiguration: JSON.parse( localStorage.getItem( "user" ) ).enablezoneconfiguration,
      // departments: ['San salvador','Santa Ana', 'Soyapango'],
      // municipalities: ['San salvador','Santa Ana', 'Soyapango','San Miguel'],
      ItemsTypeInvoice: [
        { id: "FCF", value: "CONSUMIDOR FINAL"  },
        { id: "CCF", value: "CRÉDITO FISCAL"  },
        { id: "FNS", value: "SUJETO EXCLUIDO"  },
        { id: "FEX", value: "EXPORTACIÓN"  },
        { id: "CDD", value: "COMPROBANTE DE DONACIÓN"  },
        { id: "DNR", value: "NOTA DE REMISIÓN"  },
      ],
      sellersList: [],
      search: {
        business: JSON.parse(localStorage.getItem("user")).businessid,
        branch: JSON.parse(localStorage.getItem("user")).branch,
      },
    };
  },
  filters: {
    uppercase(value) {
      return value.toUpperCase();
    },
  },
  watch: {
    isBusiness() {
      this.form["isBusiness"] = this.isBusiness;
      this.businessForm["isBusiness"] = this.isBusiness;
    },
    isOng() {
      this.form["isOng"] = this.isOng;
      this.businessForm["isOng"] = this.isOng;
    },
    isPublicEntity() {
      this.form["isPublicEntity"] = this.isPublicEntity;
      this.businessForm["isPublicEntity"] = this.isPublicEntity;
    },
    fullName: function (value) {
      this.form.legalname = value.toUpperCase();
    },
    date: {
      handler: function () {
        this.form.birthday = this.date;
        this.calculateAgeWithDOB();
      },
      immediate: true,
    },
    /*"businessForm.legaltaxpayersize"() {
      if (this.businessForm.legaltaxpayersize == "GRANDE") {
        this.businessForm.legalagentretain = true
      }
      else {
        this.businessForm.legalagentretain = false
      }
    },*/
  },
  async mounted() {
    this.$store.dispatch("rolesUser/getAllRoles");
    await this.prefixList();
    await this.getAllDocumentTypes();
    this.roleuser = JSON.parse(localStorage.getItem("user")).roles[0];
    this.business = JSON.parse(localStorage.getItem("user")).businessid || "";
    this.branch = JSON.parse(localStorage.getItem("user")).branch || "";
    this.user = JSON.parse(localStorage.getItem("user")).id || "";
    this.businesstype = JSON.parse(localStorage.getItem("user")).businesstype || "";
    if (this.form.country != "El Salvador") {
      this.getStates();
      this.getCities();
    }
    if (this.customerToShow) {
      // console.log('custm show',this.customerToShow); 
     
      this.isBusiness = this.customerToShow.Is_a_Business == 1 ? true : false || false;
      this.isOng = this.customerToShow.Is_a_Ong  == 1 ? true : false || false;
      this.isPublicEntity = this.customerToShow.Is_a_PublicEntity == 1 ? true : false || false;
      let objectToAdd = null;
      if (this.customerToShow.Is_a_Business  || this.customerToShow.Is_a_Ong || this.customerToShow.Is_a_PublicEntity) {
        objectToAdd = "businessForm";
      } else {
        objectToAdd = "form";
      }
      // console.log( 'this.customerToShow.isGlobalBranch ', this.customerToShow.isGlobalBranch );
      Object.keys(this.customerToShow).map((el) => {
        if (el == "DateofBirth") {
          this.date = this.customerToShow[el];
        } else if (el == "Is_a_Business") {
          Vue.set(this[objectToAdd], "isBusiness", this.customerToShow[el]);
        } else if (el == "ID_DocumentType") {
          Vue.set(this[objectToAdd], "documenttype", this.customerToShow[el]);
        } else if (el == "BusinessMobileNumber") {
          Vue.set(this[objectToAdd], "businessmobile", this.customerToShow[el]);
        } else if (el == "isGlobalBranch") {
          Vue.set( this[objectToAdd], "isGlobalBranch", this.customerToShow[el] );
        } else if (el == "ID_Seller") {
          Vue.set(this[objectToAdd], "idseller", this.customerToShow[el]);
        } else {
          Vue.set(this[objectToAdd], el.toLowerCase(), this.customerToShow[el]);
        }
      });

      this.form.country = this.customerToShow.Country;
      this.form.department = this.customerToShow.Department;
      this.getStates();
      this.getCities();
      Vue.set( this[ objectToAdd ], "typedDescActividad", this.customerToShow[ "descActividad" ] );
      Vue.set( this[ objectToAdd ], "CountryMH", this.customerToShow[ "CountryMH" ] );
      //Applying Masks
      if (this[objectToAdd].dui) {
        if (this[objectToAdd].documenttype == 1) {
          this[objectToAdd].dui = applyDUIMaskOutOfInput(this[objectToAdd].dui);
        }
      }
      this[objectToAdd].legalnrc &&
        (this[objectToAdd].legalnrc = await applyDUIMaskOutOfInput(
          this[objectToAdd].legalnrc
        ));
      this[objectToAdd].legalnit &&
        (this[objectToAdd].legalnit = applyNitMaskHandlingHomologationOutOfInput(
          this[objectToAdd].legalnit
        ));

      this.onChangeDocumentTypeSelect(this[objectToAdd].documenttype);

      if (this.customerToShow.Is_a_Business  || this.customerToShow.Is_a_Ong || this.customerToShow.Is_a_PublicEntity) {
        this.form = this.businessForm;
      } else {
        this.businessForm = this.form;
      }
      this.isPatient = this.customerToShow.ID_Patient ? true : false;
    } else {
      this.form.status = 1;
      this.businessForm.status = 1;

      this.sysParametersDefault.forEach((element) => {
        if (element.ParameterName == "country") {
          this.form.country = element.ParameterValue;
        } else if (element.ParameterName == "department") {
          this.form.department = element.ParameterValue;
        } else if (element.ParameterName == "city") {
          this.form.municipality = element.ParameterValue;
        }
      });
    }
    await this.getZoneConfiguration()
    await this.getAllSellers()
    /*     console.log('form normal',this.form);
        console.log('form business ',this.businessForm); */
  },
  computed: {
    dialogWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "95%" : "60%";
    },
    modeEnterprise(){
      if(this.isBusiness || this.isOng || this.isPublicEntity)
        return true;
      return false;
    },
    checkCountry() {
      if (this.form.country == "El Salvador") return true;
      else return false;
    },
    CurrentNameActivity() {
      const name = JSON.parse(localStorage.getItem("branch")).Country;

      if (name == "El Salvador") {
        return "Actividad Economica *";
      } else {
        return "Sector Legal *";
      }
    },
    FullName() {
      if (!this.isBusiness && this.form.firstname && this.form.lastname) return this.form.firstname + " " + this.form.lastname;
      else if (!this.isBusiness && this.form.firstname) return this.form.firstname;
      else if (!this.isBusiness && this.form.lastname) return this.form.lastname;
      else return "";
    },
    FullNameBusiness() {
      if (this.businessForm.businessname) return this.businessForm.legalname;
      else return "";
    },
    ...mapState("rolesUser", {
      rolesStatus: (state) => state.roles,
    }),
    ...mapGetters(["getCatalogMH"]),
    ...mapState("sysparameters", {
      sysParametersDefault: (state) => state.parameters,
    }),
    departments() {
      return this.getCatalogMH("Departamento");
    },
    municipalities() {
      const municipalities = this.getCatalogMH("Municipio");
      if (this.form.department) {
        const department = municipalities.filter(
          (mun) => mun.Departamento == this.form.department
        );
        return department;
      }
      return [];
    },
    activities() {
      return this.getCatalogMH("ActividadEconomica");
    },
    countriesMh() {
      return this.getCatalogMH("Pais");
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
    paymentTypes() {
      return this.getCatalogMH("FormaPago");
    },
  },
  methods: {
    updateSellersList(payload) {
      this.sellersList = [...payload];
    },
    async getAllSellers() {
      try {
        const result = await this.$API.sellers.getAllSellers();
        this.sellersList = result;
      } catch (error) {
        console.log("error en los sellers ", error);
      }
    },
    async getStates() {
      if (this.form.country == "El Salvador") {
        this.form.phonenumber = this.form.phonenumber.substring(0, 8);
        return [];
      }
      if (this.form.country) {
        this.noDataLabelStates = "Cargando...";
        this.stateList = [];

        this.stateList = await this.$API.general.getStates(this.form.country);
        this.noDataLabelStates = "No hay Estados disponibles";
      }
    },
    async getCities() {
      if (this.form.country == "El Salvador") return [];
      if (this.form.department) {
        this.noDataLabelCities = "Cargando...";
        this.cityList = [];

        this.cityList = await this.$API.general.getCities(this.form.department);

        this.noDataLabelCities = "No hay Ciudades disponibles";
      }
    },
    async maskNum() {
      if (this.form.mobilenumber) {
        this.form.mobilenumber = await numberSV(this, this.form.mobilenumber);
      }

      if (this.form.phonenumber) {
        if (this.form.country == "El Salvador") {
          this.form.phonenumber = await numberSV(this, this.form.phonenumber);
        }
      }

      if (this.businessForm.businessmobile) {
        this.businessForm.businessmobile = await numberSV(
          this,
          this.businessForm.businessmobile
        );
      }

      if (this.businessForm.businesscontactmobile) {
        this.businessForm.businesscontactmobile = await numberSV(
          this,
          this.businessForm.businesscontactmobile
        );
      }
    },
    async prefixList() {
      this.prefijo = await this.$API.general.getParameterPool("Prefix");
    },
    getNameTypeDocument() {
      try {
        if (this.form.documenttype && this.documentTypes) {
          const document = this.documentTypes.find(
            (doc) => doc.ID_DocumentType == this.form.documenttype
          );
          if (document.DocumentType && document.DocumentType != "NIT")
            return document.DocumentType;
          else return "";
        } else return "Seleccione un tipo de documento";
      } catch (error) {
        throw new Error(
          `Ocurrió el siguiente error al intentar cambiar el tipo de documento activo ${error}`
        );
      }
    },
    onChangeDocumentTypeSelect(event) {
      try {
        this.reactive.documentLabel = this.getNameTypeDocument();

        const x = this.documentTypes.find(
          ({ ID_DocumentType }) => ID_DocumentType === event
        );
        if (!x) return;
        const DocumentType = x.DocumentType;
        if (DocumentType === "NIT") {
          this.reactive.showDocument = false;
          setTimeout(() => {
            this.form.dui = "";
            this.reactive.isNit = true;
          }, 325);
        } else if (this.reactive.isNit) {
          this.reactive.isNit = false;
          setTimeout(() => {
            this.reactive.showDocument = true;
          }, 325);
        }
      } catch (error) {
        console.log(error);
        this.showAlert(
          "danger",
          "Error!",
          `Ha ocurrido el siguiente error al escuchar cambios en la selección de Tipos de Documento: ${error.message}`
        );
      }
    },
    async getAllDocumentTypes() {
      try {
        this.documentTypes = [
          {
            DocumentType: 'Sin Asignar',
            ID_DocumentType: 0,
            ID_DocumentTypeMh: null
          },
          ...await this.$API.documentTypes.getDocumentTypes()
        ];
      } catch (error) {
        this.showAlert("error", "Error!", `${error.message}`);
      }
    },
    async maskNit(e) {
      if (e.which === 8) return false;
      if (this.form.legalnit) {
        this.form.legalnit = await nitMaskHandlingHomologation(this, this.form.legalnit);
      } else if (this.businessForm.legalnit) {
        this.businessForm.legalnit = await nitMaskHandlingHomologation(
          this,
          this.businessForm.legalnit
        );
      }
    },
    async maskDocumentNit(e) {
      if (e.which === 8) return false;

      this.form.legalnit &&
        (this.form.legalnit = await nitMask(this, this.form.legalnit));
    },
    async keyUpMaskNit(e) {
      if (e.which === 8) return false;
      await this.maskNitEvent(e, "businessForm", "dui");
    },
    async maskNitEvent(e, targetFather, targetChild) {
      e = await nitMask(this, this[targetFather][targetChild]);
      this[targetFather][targetChild] = e;
    },
    async maskNRC() {
      if (this.form.legalnrc) {
        this.form.legalnrc.length > 1 &&
          (this.form.legalnrc = await nrcMask(this, this.form.legalnrc));
      } else if (this.businessForm.legalnrc) {
        this.businessForm.legalnrc.length > 1 &&
          (this.businessForm.legalnrc = await nrcMask(this, this.businessForm.legalnrc));
      }
    },
    varifycontent(targetFather, targetChild) {
      if (this[targetFather].documenttype == 1) {
        duiMask(this, this[targetFather][targetChild]).then((result) => {
          this[targetFather][targetChild] = result;
        });
      }
      // Eliminar letras del campo de entrada

      // if(this.checkCountry)
      // {
      //   this.$nextTick(() => {
      //     this.form.dui = this.form.dui.replace(/[^0-9-]/g, "");
      //   });

      //   let numericValue = this.form.dui
      //   numericValue = numericValue.replace(/-/g, "");
      //   let formattedValue = "";

      //   for (let i = 0; i < numericValue.length; i++) {
      //     formattedValue += numericValue[i];
      //     if ((i + 1) % 8 === 0 && i !== numericValue.length - 1) {
      //       formattedValue += "-";
      //     }
      //   }

      //   this.form.dui = formattedValue;

      //   if (this.form.dui.length > 10) {
      //     this.$nextTick(() => {
      //       this.form.dui = this.form.dui.slice(0, 10);
      //     });

      //   }
      // }
    },
    changueValueactivity() {
      this.form.descactividad = this.activities.find(
        (act) => act.Codigo == this.form.codactividad
      ).Valores;
    },
    uppercaseInput(fieldName) {
      this.form[fieldName] = this.form[fieldName].toUpperCase();
    },
    uppercaseBusiness(fieldName) {
      this.businessForm[fieldName] = this.businessForm[fieldName].toUpperCase();
    },
    redirectoAdvet() {
      this.$API.customers.getOneCustomer(this.dataresponse.customer).then((res) => {
        this.$store.dispatch("addWindow", {
          name: "VeterinaryPatientAdd",
          component: "VeterinaryPatientAdd",
          unique: false,
          meta: { customerToShow: this.dataresponse, customerNew: res },
        });
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
      });
    },
    closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "redirectPatient") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid }).then(() => {
          this.$store.dispatch("reloadData", "customers");

          this.$store.commit("patientData/changeView", "tab-1");

          this.$store.dispatch("addWindow", {
            name: "patientData",
            component: "PatientData",
            unique: false,
            meta: { patient: this.dataresponse },
          });
        });
      }

      if (this.alert.options == "redirectDetailsC") {
        //falta el id del cliente
        console.log("el data response", this.dataresponse);
        this.$store.dispatch("addWindow", {
          name: "customerData",
          component: "CustomerData",
          unique: false,
          meta: { customerToShow: this.dataresponse, userNew: true },
        });

        this.$emit("reloaderchild");
      }

      if (this.alert.options == "redirectDetails") {
        const business = JSON.parse(localStorage.getItem("user")).businesstype || null;
        if (business == "veterinary") {
          this.redirectoAdvet();

          return;
        }

        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid }).then(() => {
          this.$store.dispatch("reloadData", "customers");
          if (this.isBusiness) {
            this.$store.commit("customerData/changeView", "tab-2");
            if (this.businesstype == "accounting_and_bill")
              this.$store.commit("customerData/changeView", "tab-1");

            this.$store.dispatch("addWindow", {
              name: "customerData",
              component: "CustomerData",
              unique: false,
              meta: { customerToShow: this.dataresponse },
            });
            this.$emit("reloaderchild");
          } else {
            this.$store.dispatch("reloadData", "customers");
            this.$store.commit("customerData/changeView", "tab-2");
            if (this.businesstype == "accounting_and_bill")
              this.$store.commit("customerData/changeView", "tab-1");

            this.$store.dispatch("addWindow", {
              name: "customerData",
              component: "CustomerData",
              unique: false,
              meta: { customerToShow: this.dataresponse, userNew: true },
            });
            this.$emit("reloaderchild");
          }
        });
      }

      if (this.alert.options == "redirectcustomer") {
        //this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("addWindow", {
          name: "customerList",
          component: "CustomersList",
          unique: true,
        });
        this.$store.dispatch("reloadData", "customers");
      }
      if (this.alert.options == "redirectcustomerdeleted") {
        this.$emit("reloadData", "delete");
        this.loading = false;
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("reloadData", "customers");
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletecustomer") {
        this.deleteCustomer();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    mergedata() {
      if (!this.isBusiness && !this.customerToShow) {
        this.form.legalname = this.form.firstname + " " + this.form.lastname;
        /*   this.legalname += 1 */
        console.log(this.form.legalname);
      }
    },
    addCustomerHandler(addPatient = false) {
      if (!this.valid) {
        this.showAlert(
          "warning",
          "Advertencia",
          "Debe completar todos los campos obligatorios"
        );
        return;
      }
      if (
        (!this.businessForm.legalname ||
          this.businessForm.legalname == null ||
          this.businessForm.legalname == "") &&
        this.isBusiness
      ) {
        this.showAlert(
          "warning",
          "Advertencia",
          "El nombre legal es obligatorio para negocios"
        );
        return;
      }
      if ((!this.isBusiness || this.isBusiness == false || this.isBusiness == null) && (this.form.legalname == null || this.form.legalname == "" || !this.form.legalname)) {
        console.log('Guardando NOT IS BUSINESS');
        this.form.legalname = this.FullName;
      }
      if( this.isBusiness ) {
        // console.log( this.form.country, this.businessForm.country );
        if( this.form.country === 'El Salvador' ) {
          if ( this.form.codactividad == null || this.form.codactividad == "" ) {
            this.showAlert("warning", "Advertencia", "La actividad economica es obligatoria");
            return;
          }
        } else {
          // console.log( this.businessForm.typedDescActividad );
          if( !this.businessForm.typedDescActividad || this.businessForm.typedDescActividad === '' ) {
            this.showAlert("warning", "Advertencia", "La actividad economica es obligatoria");
            return;
          }
        }

      }

      if (this.form.legalnit) {
        if (
          !validateNITHomologation(
            this.isBusiness ? "businessForm" : "form",
            this,
            "legalnit",
            this.isBusiness
              ? "Número de NIT"
              : "Número de NIT en la Información Tributaria"
          )
        ) {
          return;
        }
      }
      // console.log(validateNITHomologation( this.isBusiness ? 'businessForm' : 'form', this, 'legalnit' ));
      // this.businessForm.legalname = this.FullNameBusiness;

      let obj = this.prepareCustomerPayload();
      obj.iduserinsert = this.user;
      if (addPatient == true) {
        obj.isAddPatient = true;
      }
      // console.log("objeto a enviar", obj.CountryMH);
      // return;

      this.loading = true;
      this.$API.customers
        .addCustomer(obj)
        .then((res) => {
          this.loading = false;
          console.log(res);
          this.dataresponse = res.data;
          if (addPatient == true) {
            if (res?.extraMessage != "") {
              this.showAlert(
                "warning",
                "Ingreso correcto",
                "Datos del cliente ingresados correctamente (se ha agregado al cliente como paciente en el sistema)" + res?.extraMessage,
                "redirectPatient"
              );
            }
            else {
              this.showAlert(
                "success",
                "Ingreso correcto",
                "Datos del cliente ingresados correctamente (se ha agregado al cliente como paciente en el sistema)",
                "redirectPatient"
              );
            }
            
          } else {
            if (res?.extraMessage != "") {
              this.showAlert(
                "warning",
                "Ingreso correcto",
                "Datos del cliente ingresados correctamente" + res?.extraMessage,
                "redirectDetails"
              );
            }
            else {
              this.showAlert(
                "success",
                "Ingreso correcto",
                "Datos del cliente ingresados correctamente",
                "redirectDetails"
              );
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Ocurrio un problema",
            "Ha ocurrido un problema por favor contacte al administrador"
          );
        });
    },
    addressDetails(event, title) {
      this.form[title] = event;
      this.businessForm[title] = event;
      if (title == "address" && !this.isBusiness) {
        this.form["legaladdress"] = event;
        this.businessForm["legaladdress"] = event;
      }
    },
    prepareCustomerPayload() {
      try {

        let obj = this.modeEnterprise
          ? {
              ...this.businessForm, //dui: this.form.dui,
              country: this.form.country,
              department: this.form.department,
              municipality: this.form.municipality,
              codactividad: this.form.codactividad,
              descactividad: this.form.country === 'El Salvador' ? this.form.descactividad : this.businessForm.typedDescActividad,
              isGlobalBranch: this.form.isGlobalBranch,
              CountryMH: this.form.CountryMH,
              //documenttype: this.form.documenttype,
              prefijo: this.form.prefijo,
              limitcredit: this.form.limitcredit,
            }
          : { ...this.form };

        if (this.form.country != "El Salvador") {
          obj.city = this.form.municipality;
          obj.state = this.form.department;
        }

        obj.idbranch = this.branch;
        obj.idbusiness = this.business;
        obj.dui && (obj.dui = obj.dui.replaceAll("-", ""));
        obj.legalnit && (obj.legalnit = obj.legalnit.replaceAll("-", ""));
        obj.legalnrc && (obj.legalnrc = obj.legalnrc.replaceAll("-", ""));
        return obj;
      } catch (error) {
        throw new Error(
          `Ocurrió el siguiente error al intentar preparar la carga útil del cliente`
        );
      }
    },
    updateCustomer() {
      try {
        if (!this.valid) {
          this.showAlert(
            "warning",
            "Advertencia",
            "Debe completar todos los campos obligatorios"
          );
          return;
        }
        if (this.form.legalnit) {
          if (
            !validateNITHomologation(
              this.isBusiness ? "businessForm" : "form",
              this,
              "legalnit",
              this.isBusiness
                ? "Número de NIT"
                : "Número de NIT en la Información Tributaria"
            )
          ) {
            return;
          }
        }

        let obj = this.prepareCustomerPayload();
        
        if(obj.legalname == "" || !obj.legalname) {
          if(!this.isBusiness && this.FullName && this.FullName != "") {
            obj.legalname = this.FullName;
          }
          else {
            this.showAlert("warning", "Advertencia", "El nombre legal es obligatorio");
            return;
          }
        }
        obj.id_customer = this.customerToShow.ID_Customer;
        obj.iduserupdate = this.user;
        this.loading = true;
        console.log("objeto a modificar", obj);
        this.$API.customers
          .updateCustomer(obj)
          .then((response) => {
            // throw new Error("Error auto generado"); usar si se necesita probar el catch
            this.loading = false;

            if (response?.extraMessage != "") {
              this.showAlert(
                "warning",
                "Actualización Correcta",
                response.extraMessage,
                //"Datos del cliente actualizados correctamente",
                "updateCustomer"
              );
            }
            else {
              this.showAlert(
                "success",
                "Actualización Correcta",
                response.message,
                //"Datos del cliente actualizados correctamente",
                "updateCustomer"
              );
            }
            
            this.$store.dispatch("reloadData", "customers");
          })
          .catch(() => {
            this.loading = false;
            this.showAlert(
              "danger",
              "Ocurrio un problema",
              "Ha ocurrido un problema por favor contacte al administrador o intente mas tarde"
            );
          });
      } catch (error) {
        this.loading = false;
        this.showAlert(
          "danger",
          "Ocurrio un problema",
          "Ha ocurrido un problema por favor contacte al administrador o intente mas tarde"
        );
      }
    },
    onEditorChange(e) {
      this.form.notes = e.html;
      this.businessForm.notes = e.html;
      if (this.form.notes != "") {
        this.showBgcolorNotes = "tabsContent";
      } else if (this.form.notes == "" || this.form.notes == "<p><br></p>") {
        this.showBgcolorNotes = "tabs";
      } else {
        this.showBgcolorNotes = "tabs";
      }
    },

    confirmDeleteCustomer() {
      this.showAlert(
        "question",
        "Eliminar",
        "¿Seguro que desea eliminar el cliente seleccionado?",
        "deletecustomer"
      );
    },

    deleteCustomer() {
      this.loading = true;
      this.$API.customers
        .logicalDeleteCustomer(this.customerToShow.ID_Customer)
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.showAlert(
            "success",
            "Eliminación correcta",
            "Cliente eliminado correctamente",
            "redirectcustomerdeleted"
          );
        })
        .catch(() => {
          this.loading = false;
          this.showAlert(
            "danger",
            "Ocurrio un problema",
            "Ha ocurrido un problema por favor contacte al administrador o intente mas tarde"
          );
        });
    },
    addClientAsPatient() {
      this.loading = true;
      let formdata = new FormData();
      formdata.append("patient", JSON.stringify(this.form));
      this.$API.patients.addPatient(formdata).then((response) => {
        this.$API.customers
          .updateCustomer({
            id_customer: this.form.id_customer,
            patient: response.ID_Patient,
            isBusiness: this.form.isBusiness,
          })
          .then(() => {
            this.$emit("reloadData");
            this.loading = false;
            this.isPatient = true;
          });
      });
    },
    calculateAgeWithDOB() {
      var dateNow = new Date();
      var date01 = this.form.birthday;
      var date02 =
        dateNow.getDate() + "/" + (dateNow.getMonth() + 1) + "/" + dateNow.getFullYear();

      if (date01 != null && date02 != null) {
        var aDate01 = date01.split("/");
        var aDate02 = date02.split("/");

        var fDate01 = Date.UTC(aDate01[2], aDate01[1] - 1, aDate01[0]);
        var fDate02 = Date.UTC(aDate02[2], aDate02[1] - 1, aDate02[0]);

        var dif = fDate02 - fDate01;
        var age = Math.floor(dif / (1000 * 60 * 60 * 24) / 365);
        this.form.age = age;
      }
    },
    calculateDOBwithAge() {
      var dateNow = new Date();
      var years = dateNow.getFullYear();
      years = years - this.form.age;
      var dateDOB = dateNow.getDate() + "/" + (dateNow.getMonth() + 1) + "/" + years;
      this.form.birthday = dateDOB;
      this.date = dateDOB;
    },
    getZoneConfiguration() {
      this.$API.business.getZoneConfiguration()
      .then(response => {
        if (response.length > 0) {
          let data = response[0]

          if (data?.ZoneConfiguration) {
            let zones = data.ZoneConfiguration
            this.ZoneConfiguration = zones.split(' | ').map(item => item.trim());
          }
        }
      })
    }
  },
};
</script>

<style scoped>
.btn-add {
  background-color: #3498db !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}

.btnclean {
  background-color: #f29d35 !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsB:hover {
  cursor: pointer;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;
}

.tabsNH {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;
}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 15px;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}
</style>
